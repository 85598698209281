<template>
  <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact
      >Historia Clinica - {{ name_historia }}</page-header-compact
    >
    <br />
    <div class="container-xl px-4">
      <div class="card">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <br />
      <wizard
        :steps="steps.length"
        @prev="prev($event)"
        @next="next($event)"
        :current_step="current"
      >
        <template #tabs>
          <wizard-tab
            title="Motivo Consulta"
            step="1"
            active="true"
            id="step1"
            v-on:tab-click="next({ prev: current, current: 1 })"
          ></wizard-tab>
          <wizard-tab
            title="Examen Fisico"
            step="2"
            id="step2"
            v-on:tab-click="next({ prev: current, current: 2 })"
          ></wizard-tab>
          <wizard-tab
            title="Revisión por sistemas"
            step="3"
            id="step3"
            v-on:tab-click="next({ prev: current, current: 3 })"
          ></wizard-tab>
          <wizard-tab
            title="Situaciones Personales"
            step="4"
            id="step4"
            v-on:tab-click="next({ prev: current, current: 4 })"
          ></wizard-tab>
          <wizard-tab
            title="Apgar Familiar"
            step="5"
            id="step5"
            v-on:tab-click="next({ prev: current, current: 5 })"
          ></wizard-tab>
          <wizard-tab
            title="Control"
            step="6"
            id="step6"
            v-on:tab-click="next({ prev: current, current: 6 })"
          ></wizard-tab>
          <wizard-tab
            title="EPOC"
            step="7"
            id="step7"
            v-on:tab-click="next({ prev: current, current: 7 })"
          ></wizard-tab>
          <wizard-tab
            title="MINI-MENTAL"
            step="8"
            id="step8"
            v-on:tab-click="next({ prev: current, current: 8 })"
          ></wizard-tab>
          <wizard-tab
            title="Impresión Diagnostica"
            step="9"
            id="step9"
            v-on:tab-click="next({ prev: current, current: 9 })"
          ></wizard-tab>
          <wizard-tab
            title="Ordenes"
            step="10"
            id="step10"
            v-on:tab-click="next({ prev: current, current: 10 })"
          ></wizard-tab>
          <wizard-tab
            title="Formulación"
            step="11"
            id="step11"
            v-on:tab-click="next({ prev: current, current: 11 })"
          ></wizard-tab>
          <wizard-tab
            title="Lawton y Brody"
            step="12"
            id="step12"
            v-on:tab-click="next({ prev: current, current: 12 })"
          ></wizard-tab>
          <wizard-tab
            title="Barthel"
            step="13"
            id="step13"
            v-on:tab-click="next({ prev: current, current: 13 })"
          ></wizard-tab>
          <wizard-tab
            title="Finnish Risk"
            step="14"
            id="step14"
            v-on:tab-click="next({ prev: current, current: 14 })"
          ></wizard-tab>
          <wizard-tab
            title="Framingham"
            step="15"
            id="step15"
            v-on:tab-click="next({ prev: current, current: 15 })"
          ></wizard-tab>
          <wizard-tab
            title="Linda Fried"
            step="16"
            id="step16"
            v-on:tab-click="next({ prev: current, current: 16 })"
          ></wizard-tab>
          <wizard-tab
            title="Plan de Intervención"
            step="17"
            id="step17"
            v-on:tab-click="next({ prev: current, current: 17 })"
          ></wizard-tab>
          <wizard-tab
            title="Recomendaciones"
            step="18"
            id="step18"
            v-on:tab-click="next({ prev: current, current: 18 })"
          ></wizard-tab>
          <wizard-tab
            title="Resumen"
            step="19"
            id="step19"
            v-on:tab-click="next({ prev: current, current: 19 })"
          ></wizard-tab>
        </template>
        <template #tab-content>
          <wizard-tab-pane id="step1" active="true">
            <template #content>
              <component
                :is="currentComponent"
                :ref="currentComponent"
                v-bind="currentProperties"
              ></component>
            </template>
          </wizard-tab-pane>
        </template>
        <template #finish>
          <div class="row">
            <remision-component
              :idHistoria="idHistoria"
              :usuario="usuario"
            ></remision-component>
            <incapacidad-component
              :idHistoria="idHistoria"
              :usuario="usuario"
            ></incapacidad-component>
            <finalizar-historia
              :id-usuario="usuario.id"
              :id-historia="idHistoria"
              :codigoHistoria="'VEJ'"
            ></finalizar-historia>
          </div>
        </template>
      </wizard>
    </div>
  </main>
</template>
<script>
import Wizard from "../../../components/common/utilities/Wizard/Wizard";
import WizardTab from "../../../components/common/utilities/Wizard/WizardTab";
import WizardTabPane from "../../../components/common/utilities/Wizard/WizardTabPane";
import motivoConsultaComponent from "../base/motivoConsulta/motivoConsulta";
import examenFisicoComponent from "../base/examenFisico/examenFisico";
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import historiaClinicaService from "../../../services/historiaClinicaService";
import ordenComponent from "../base/orden/ordenComponent";
import formulaMedicaComponent from "../base/formulaMedica/formulaMedicaComponent";
import impresionDXComponent from "../base/impresionDX/impresionDXComponent";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import resumenHcComponent from "./resumenHc/resumenHcComponent";
import controlComponent from "./controlComponent";
import situacionesPersonalesComponent from "../base/baseSituacionesPersonales/indexSituacionesPersonales";
import intervenciones from "@/views/historias/base/intervenciones/intervenciones";
import recomendaciones from "@/views/historias/base/recomendaciones/recomendaciones";
import revisionSistemas from "@/views/historias/base/baseUrgencia/revision";
import FinalizarHistoria from "@/views/historias/base/finalizarHistoria/finalizarHistoria";
import RemisionComponent from "../base/baseRemision/index";
import epocComponent from "../base/baseRespuestaFormularios/index";
import incapacidadComponent from "../base/baseIncapacidades/index";
import dayjs from "dayjs";
import minimentalComponent from "../base/baseMinimental/index";
import indexLawton from "../base/baseLawton/indexLawton";
import indexBarthel from "../base/baseBarthel/indexBarthel";
import indexFinnisRisk from "../base/baseFinnisRisk/indexFinnisRisk";
import indexFragmingam from "../base/baseFragmingam/indexFragmingam";
import apgarFamiliar from "../base/baseApgarFamiliar/apgarHistoria";
import lindaFried from "./tests/LindaFriedComponent.vue";
export default {
  components: {
    FinalizarHistoria,
    PageHeaderCompact,
    motivoConsultaComponent,
    examenFisicoComponent,
    WizardTabPane,
    WizardTab,
    Wizard,
    ordenComponent,
    formulaMedicaComponent,
    impresionDXComponent,
    modalInformacionUsuario,
    cardInformacionUsuario,
    resumenHcComponent,
    controlComponent,
    situacionesPersonalesComponent,
    intervenciones,
    recomendaciones,
    revisionSistemas,
    RemisionComponent,
    epocComponent,
    incapacidadComponent,
    minimentalComponent,
    indexBarthel,
    indexFinnisRisk,
    indexFragmingam,
    indexLawton,
    apgarFamiliar,
    lindaFried
  },
  data() {
    return {
      finalizada: "",
      idHistoria: "",
      idTipoHistoria: "",
      usuario: {},
      current: 1,
      checkpoint: 1,
      steps: [
        { stepId: "#step1", ref: "motivoConsultaComponent", validate: true },
        { stepId: "#step2", ref: "examenFisicoComponent", validate: true },
        { stepId: "#step3", ref: "revisionSistemas", validate: true },
        {
          stepId: "#step4",
          ref: "situacionesPersonalesComponent",
          validate: true,
        },
        {
          stepId: "#step5",
          ref: "apgarFamiliar",
          validate: true,
        },
        { stepId: "#step6", ref: "controlComponent", validate: false },
        { stepId: "#step7", ref: "epocComponent", validate: true },
        { stepId: "#step8", ref: "minimentalComponent", validate: true },
        { stepId: "#step9", ref: "impresionDXComponent", validate: true },
        { stepId: "#step10", ref: "ordenComponent", validate: false },
        { stepId: "#step11", ref: "formulaMedicaComponent", validate: false },
        { stepId: "#step12", ref: "indexLawton", validate: true },
        { stepId: "#step13", ref: "indexBarthel", validate: true },
        { stepId: "#step14", ref: "indexFinnisRisk", validate: true },
        { stepId: "#step15", ref: "indexFragmingam", validate: false },
        { stepId: "#step16", ref: "lindaFried", validate: true },
        { stepId: "#step17", ref: "intervenciones", validate: false },
        { stepId: "#step18", ref: "recomendaciones", validate: false },
        { stepId: "#step19", ref: "resumenHcComponent", validate: false },
      ],
      name_historia: "",
    };
  },
  methods: {
    prev(step) {
      this.current = step.current;
      this.activeTab();
    },
    next(step) {
      if (step.prev <= this.checkpoint && step.current - 1 > step.prev) {
        this.activeTab();
        return;
      }

      const refComponent = this.steps[step.prev - 1].ref;

      if (typeof this.$refs[refComponent].save !== "undefined") {
        this.$refs[refComponent].save();
      }

      if (this.steps[step.prev - 1].validate) {
        if (this.$refs[refComponent].$v.$invalid) return;
      }

      if (step.current > this.checkpoint) {
        historiaClinicaService.updateCheckpoint(this.idHistoria, step.current);
        this.checkpoint = step.current;
      }

      this.current = step.current;

      this.activeTab();
    },
    async cargar(id) {
      const response = await historiaClinicaService.show(id);

      this.usuario = response.data.usuario;
      this.finalizada = response.data.finalizada;
      this.current = response.data.checkpoint;
      this.checkpoint = response.data.checkpoint;
      this.idTipoHistoria = response.data.id_tipo_historia;
      this.name_historia = response.data.tipo_historia.nombre;

      this.activeTab();
    },
    activeTab() {
      document.querySelectorAll(`a.active`).forEach((a) => {
        a.classList.remove("active");
      });
      document
        .querySelector(`a[href='${this.steps[this.current - 1].stepId}']`)
        .classList.add("active");
    },
  },
  created() {
    this.idHistoria = this.$route.params.id;
    this.cargar(this.idHistoria);
  },
  computed: {
    currentProperties() {
      if (this.currentComponent === "motivoConsultaComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
        };
      }
      if (this.currentComponent === "examenFisicoComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          modo: "VEJEZ",
        };
      }

      if (this.currentComponent === "situacionesPersonalesComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario,
          idUsuario: this.usuario.id,
          edad: this.edad,
        };
      }

      if (this.currentComponent === "controlComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario,
          idUsuario: this.usuario.id,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "epocComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario,
          codigo: "EPOC",
        };
      }
      if (this.currentComponent === "minimentalComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario,
          idUsuario: this.usuario.id,
        };
      }

      if (this.currentComponent === "impresionDXComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        };
      }

      if (this.currentComponent === "ordenComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario: this.usuario,
          idTipoHistoria: this.idTipoHistoria,
        };
      }
      if (this.currentComponent === "formulaMedicaComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario: this.usuario,
        };
      }

      if (this.currentComponent === "instrumentoComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,

          sexo: this.usuario.sexo,
          fecha_nacimiento: this.usuario.fecha_nacimiento,
        };
      }
      if (this.currentComponent === "resumenHcComponent") {
        return {
          idHistoria: this.idHistoria,
          idUsuario: this.usuario.id,
          edad: this.edad,
        };
      }

      if (this.currentComponent === "revisionSistemas") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          sexo: this.usuario.sexo,
        };
      }

      if (this.currentComponent === "intervenciones") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario.sexo,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "recomendaciones") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario.sexo,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "indexLawton") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,

          sexo: this.usuario.sexo,
          fecha_nacimiento: this.usuario.fecha_nacimiento,
        };
      }

      if (this.currentComponent === "indexBarthel") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,

          sexo: this.usuario.sexo,
          fecha_nacimiento: this.usuario.fecha_nacimiento,
        };
      }
      if (this.currentComponent === "indexFinnisRisk") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,

          sexo: this.usuario.sexo,
          fecha_nacimiento: this.usuario.fecha_nacimiento,
        };
      }

      if (this.currentComponent === "indexFragmingam") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,

          sexo: this.usuario.sexo,
          fecha_nacimiento: this.usuario.fecha_nacimiento,
        };
      }

      if (this.currentComponent === "apgarFamiliar") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
          usuario: this.usuario,
          idTipoHistoria: this.idTipoHistoria,
          type:'adult'
        };
      }

      if (this.currentComponent === "lindaFried") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
          usuario: this.usuario,
          codigo: "LINDA_FRIED",
        };
      }

      return {};
    },
    currentComponent() {
      let components = [
        "motivoConsultaComponent",
        "examenFisicoComponent",
        "revisionSistemas",
        "situacionesPersonalesComponent",
        "apgarFamiliar",
        "controlComponent",
        "epocComponent",
        "minimentalComponent",
        "impresionDXComponent",
        "ordenComponent",
        "formulaMedicaComponent",
        "indexLawton",
        "indexBarthel",
        "indexFinnisRisk",
        "indexFragmingam",
        "lindaFried",
        "intervenciones",
        "recomendaciones",
        "resumenHcComponent",
      ];

      return components[this.current - 1];
    },
    edad: function () {
      const now = dayjs();
      return now.diff(this.usuario.fecha_nacimiento, "years");
    },
  },
};
</script>

<style scoped></style>
